export class InputFile {
  constructor() {
    const input_file = document.getElementById('input_file')
    const input_return = document.getElementById('input_return')

    if (!input_file) return

    input_file.addEventListener('change', () => {
      const file_list = input_file.files
      for (let i = 0; i < file_list.length; i++) {
        input_return.innerText = file_list[i].name
      }
    })
  }
}
