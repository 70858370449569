import { IsIE } from './Ua'

export class IeModal {
  constructor(siteName, siteUrl) {
    this.siteName = siteName
    this.siteUrl = siteUrl

    if (IsIE()) {
      const page = document.querySelector('#page')
      const ieModal = `<div class="c-alert_ie"><div class="c-alert_ie__alert">${this.siteName}のWebサイトはInternet Explorerに対応しておりません。<br>本サイトを閲覧するためには現在のMicrosoft公式ブラウザEdgeを利用して閲覧ください。</a><a class="c-btn_edge" href="microsoft-edge:${this.siteUrl}">Microsoft Edgeで閲覧する​​​​​</a></div></div>`
      page.insertAdjacentHTML('beforeend', ieModal)
    }
  }
}
