export class IndexKvFixed {
  constructor() {
    if (document.getElementById('js-index_kv') == null) return
    const kv = document.getElementById('js-index_kv')
    let targetPosition = 0
    if (window.innerWidth > 768) {
      targetPosition = 1000
    } else {
      targetPosition = 800
    }
    window.addEventListener('scroll', (e) => {
      var y = window.pageYOffset
      if (y > targetPosition) {
        kv.classList.add('is-kv_fixed')
      } else {
        kv.classList.remove('is-kv_fixed')
      }
    })

    document.addEventListener('DOMContentLoaded', () => {
      if (location.hash) {
        const kvHeight = document.getElementById('js-index_kv').clientHeight
        setTimeout(() => {
          window.scrollBy(0, -kvHeight)
        }, 100)
      }
    })
  }
}
