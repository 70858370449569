import Swiper from 'swiper'
import 'swiper/css'

export class LowerColumnSlider {
  constructor() {
    if (document.getElementById('js-lower-column_slider') == null) return
    const sliderEl = document.getElementById('js-lower-column_slider')
    sliderEl.style.overflow = 'visible'

    const mySwiper = new Swiper(sliderEl, {
      slidesPerView: 1.6,
      spaceBetween: 20,
      loop: true,
      breakpoints: {
        767: {
          slidesPerView: 2.7,
          spaceBetween: 20,
        },
        1000: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },
    })
  }
}
