import Vue from 'vue/dist/vue.esm.js'
import axios from 'axios'

export class VueSearchBaseInformation {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementById('js-search-base') == null) return
      if (document.getElementById('vue-search-base') == null) return
      axios.get('/wp-json/wp/v2/custom_base').then(function (res) {
        const vm = new Vue({
          el: '#vue-search-base',
          data: {
            posts: res.data,
            base_prefectures: vueBasePrefectures,
            base_type: vueBaseType,
          },
          computed: {
            filteredPosts: function () {
              let posts = this.posts
              // フィルタリングの部分を関数にして呼ぶ
              const filtering = (post, keyStr) => {
                let isChecked = false
                let isDefault = true
                this[keyStr].forEach((cat) => {
                  if (cat.checked) {
                    isDefault = false
                    if (post[keyStr].includes(cat.slug)) {
                      isChecked = true
                    }
                  }
                })
                return isDefault || isChecked
              }
              posts = posts.filter((post) => filtering(post, 'base_prefectures'))
              posts = posts.filter((post) => filtering(post, 'base_type'))

              return posts
            },
          },
          mounted() {
            // this.base_prefectures.forEach((term) => {
            //   if (term.checked) {
            //     this.currentCat = term.slug
            //   }
            // })
            this.base_type.forEach((term) => {
              if (term.checked) {
                this.currentCat = term.slug
              }
            })
          },
          methods: {
            toggleCheck: function (item) {
              item.checked = !item.checked
            },
            changeCat: function () {
              // this.base_prefectures.forEach((term) => {
              //   if (term.slug == this.currentCat) {
              //     term.checked = true
              //   } else {
              //     term.checked = false
              //   }
              // })
              this.base_type.forEach((term) => {
                if (term.slug == this.currentCat) {
                  term.checked = true
                } else {
                  term.checked = false
                }
              })
            },
            filterByPrefecture: function (filteredPosts, prefectureSlug) {
              return filteredPosts.filter((post) => {
                return post.base_prefectures.includes(prefectureSlug)
              })
            },
            beforeEnter(el) {
              el.style.transitionDelay = 100 * parseInt(el.dataset.index, 10) + 'ms'
            },
            // トランジション完了またはキャンセルでディレイを削除
            afterEnter(el) {
              el.style.transitionDelay = ''
            },
          },
        })
      })
    })
  }
}
