import { TweenMax, Power3 } from 'gsap'
Number.isInteger =
  Number.isInteger ||
  function (value) {
    const IS_INTEGER = typeof value === 'number' && isFinite(value) && Math.floor(value) === value
    return IS_INTEGER
  }

export class RecruitNumberCount {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementById('recruit-number-list') == null || document.getElementsByClassName('js-countup').length == 0) return
      const duration = 2
      const boxes = document.querySelectorAll('.js-countup')
      const boxLength = boxes.length
      const options = {
        root: null,
        rootMargin: '0% 0px',
        threshold: 0,
      }
      const observer = new IntersectionObserver(doWhenIntersect, options)

      for (var i = 0; i < boxLength; i++) {
        observer.observe(boxes[i])
        boxes[i].innerHTML = '0'
      }

      function doWhenIntersect(entries) {
        const entriesLength = entries.length
        for (var i = 0; i < entriesLength; i++) {
          if (entries[i].isIntersecting) {
            activateIndex(entries[i].target)
          }
        }
      }

      function activateIndex(element) {
        if (element.classList.contains('is-act')) {
          return
        }
        element.classList.add('is-act')
        const from = Number(element.getAttribute('data-from'))
        const to = Number(element.getAttribute('data-to'))
        if (from == to) {
          return
        }
        if (Number.isInteger(to)) {
          countInteger(element, from, to)
        } else {
          countFloat(element, from, to, String(to).split('.')[1].length)
        }
      }
      function countInteger(target, from, to) {
        let obj = {
          count: from,
        }
        TweenMax.to(obj, duration, {
          count: to,
          ease: Power3.easeInOut,
          onUpdate: () => {
            target.textContent = Math.floor(obj.count)
          },
        })
      }

      function countFloat(target, from, to, fix) {
        let obj = {
          count: from,
        }
        let tmp = []
        TweenMax.to(obj, duration, {
          count: to,
          ease: Power3.easeInOut,
          onUpdate: () => {
            tmp = String(obj.count.toFixed(fix)).split('.')
            target.innerHTML = tmp[0] + '<span class="float">.' + tmp[1] + '</span>'
          },
        })
      }
    })
  }
}
