export class InterviewPic {
  constructor() {
    // document.addEventListener('DOMContentLoaded', function () {
    window.onload = () => {
      if (document.getElementById('js-interview_main') == null) return
      const mainElement = document.getElementById('js-interview_main')
      const picParent = document.getElementById('js-interview_pic_parent')
      const picElement = document.getElementById('js-interview_pic')
      const picItem = document.getElementById('js-interview_pic_item')
      // '#js-interview_main'の高さを取得
      const mainHeight = mainElement.offsetHeight
      const picHeight = picItem.offsetHeight

      picParent.style.height = mainHeight - picHeight + 'px'

      // スクロールイベントを監視
      window.addEventListener('scroll', function () {
        // スクロール位置を取得
        const scrollPosition = window.scrollY

        // 切り替える位置（'#js-interview_main'の高さの半分）
        const switchPosition = mainHeight / 2

        // スクロール位置が切り替える位置を超えたら写真を切り替える
        const figureElements = picElement.getElementsByClassName('p-recruit_interview__pic__item')
        if (scrollPosition > switchPosition) {
          // 2枚目の写真に'is-act'クラスを付け替え、1枚目の写真のクラスを削除
          figureElements[1].classList.add('is-act')
          figureElements[0].classList.remove('is-act')
        } else {
          // 1枚目の写真に'is-act'クラスを付け替え、2枚目の写真のクラスを削除
          figureElements[0].classList.add('is-act')
          figureElements[1].classList.remove('is-act')
        }
      })
    }
    // })
  }
}
