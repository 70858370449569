import { UaCheck } from './Ua'

export class TelGrant {
  constructor() {
    const _ua = UaCheck()

    if (!_ua.Mobile) return
    const targetList = document.querySelectorAll('span[data-action=call]')
    const node = Array.prototype.slice.call(targetList, 0)
    if (node.length == 0) return
    node.forEach(function (target) {
      const tel = target.getAttribute('data-tel')
      target.innerHTML = `<a href="tel:${tel}">${tel}</a>`
    })
  }
}
