import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export class BgScroll {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementsByClassName('c-bg_scroll') == null) return
      if (document.getElementById('js-scroll_area') == null) return

      const area = document.getElementById('js-scroll_area')
      const areaHeight = area.clientHeight
      const elm = document.getElementsByClassName('c-bg_scroll')

      gsap.set(elm, {
        width: '95%',
      })

      //タイムライン
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: elm, //トリガー
          start: 'top top', //開始位置
          end: '+=' + areaHeight + 'px', //終了位置
          scrub: true, //ピン留め
          pin: true, //スクロール量に応じて動かす
        },
      })

      tl.to(elm, { scale: 1.3, duration: 10 })
    })
  }
}
