import Swiper, { EffectFade, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export class FeatureSection {
  constructor() {
    const section = document.querySelector('.js-feature_container')
    if (!section) return

    let lastWindowWidth = window.innerWidth

    window.addEventListener('resize', () => {
      if (lastWindowWidth != window.innerWidth) {
        lastWindowWidth = window.innerWidth
        window.location.reload()
      }
    })

    const mediaQuery = window.matchMedia('(max-width:767px)')
    if (mediaQuery.matches === true) return

    const slider = document.querySelector('.js-index_feature_slider')
    const slideList = slider.querySelectorAll('.swiper-slide')

    let windowHeight = window.innerHeight //ウィンドウの高さ取得
    const scrollValue = windowHeight //スライドに必要なスクロールの長さ
    const slideLength = slideList.length //スライド枚数取得

    slider.style.height = windowHeight + 'px' //スライダー自体の高さを指定

    // スライダー部分をピン留め
    gsap.to(slider, {
      scrollTrigger: {
        trigger: '.p-feature_section',
        start: 'top top',
        end: '+=' + scrollValue * slideLength + 'px',
        scrub: true,
        pin: true,
        // markers: true,
      },
    })

    const trgList = document.querySelectorAll('.js-slide_trg')

    // スライドに対するトリガー
    trgList.forEach((trg, i) => {
      trg.dataset.index = i
      trg.style.height = scrollValue + 'px'

      gsap.to(trg, {
        scrollTrigger: {
          trigger: trg,
          start: 'top top',
          scrub: true,
          // markers: true,
          onEnter: () => changeSlide(trg),
          onEnterBack: () => changeSlide(trg),
        },
      })
    })

    // スライダー
    const swiper = new Swiper(slider, {
      modules: [EffectFade, Pagination],
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
        renderBullet: function (index, className) {
          let i = '0' + (index + 1)
          return '<span class="' + className + ' ' + '-bullet' + i + '">' + i + '.' + '</span>'
        },
      },
    })

    // スライドを変更する関数
    const changeSlide = (arg) => {
      swiper.slideTo(arg.dataset.index)
      // console.log('slide' + arg.dataset.index)
    }

    const paginationList = document.querySelectorAll('.swiper-pagination-bullet')

    // ページネーションクリック時の動作
    paginationList.forEach((pagination, i) => {
      pagination.addEventListener('click', () => {
        const targetIndex = '0' + (i + 1)
        const targetElm = document.getElementById('slide_trg' + targetIndex)
        console.log(targetElm)
        targetElm.scrollIntoView({
          block: 'end',
        })
      })
    })

    // IntersectionObserver オブジェクトを作成
    // 交差時に実行するコールバック関数を渡す
    const header = document.getElementById('js-header')
    const observer = new IntersectionObserver((entries) => {
      // entriesは監視対象すべてが入っているリスト
      for (const e of entries) {
        // isIntersecting プロパティは交差しているかどうかの真偽値
        // viewport に交差し、入ったときに isIntersecting === true、出たときに false になる
        if (e.isIntersecting) {
          header.classList.add('is-feature')
        } else {
          header.classList.remove('is-feature')
        }
      }
    })
    // observe メソッドに監視対象要素を引数として渡すことで監視されるようになります
    observer.observe(document.getElementById('js-feature'))
  }
}
