import { gsap } from 'gsap'
export class Drawer {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      if (document.getElementById('js-hamburger') == null) return
      const hamburger = document.getElementById('js-hamburger')
      if (document.getElementById('js-drawer') == null) return
      const drawer = document.getElementById('js-drawer')
      const body = document.querySelector('body')

      hamburger.addEventListener('click', (event) => {
        let $this = event.currentTarget
        $this.querySelector('span').classList.toggle('hidden')
        $this.classList.toggle('is-act')
        body.classList.add('is-drawer')

        if ($this.classList.contains('is-act')) {
          animation(drawer, 'open')
        } else {
          animation(drawer, 'close')
          body.classList.remove('is-drawer')
        }
      })

      const animation = (el, type) => {
        if (type == 'open') {
          gsap.to(el, 0.3, { autoAlpha: 1, display: 'block' })
        } else {
          gsap.to(el, 0.3, { autoAlpha: 0, display: 'none' })
        }
      }
    })
  }
}
