import { gsap } from 'gsap'

export class RecruitOpening {
  constructor() {
    const kv = document.getElementById('js-recruit_kv')

    if (!kv) return

    window.addEventListener('load', () => {
      // kv.classList.add('is-load')
      const tl = gsap.timeline()

      tl.to(kv, {
        // duration: 0.5,
        onComplete: () => {
          kv.classList.add('is-load')
        },
      })
    })
  }
}
