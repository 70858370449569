import Swiper, { Autoplay, EffectCreative } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-creative'

export class IndexKvSlider {
  constructor() {
    if (document.querySelectorAll('.js-index-kv_slider') == null) return
    const sliderEl = document.querySelectorAll('.js-index-kv_slider')

    sliderEl.forEach((slider) => {
      const mySwiper = new Swiper(slider, {
        modules: [Autoplay, EffectCreative],
        slidesPerView: 1,
        allowTouchMove: false,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        effect: 'creative',
        creativeEffect: {
          prev: {
            translate: ['-25%', 0, -1],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        },
        speed: 2000,
        autoplay: {
          delay: 3500,
        },
      })
    })
  }
}
