export class Zipcode {
  constructor() {
    if (document.getElementById('js-zipcode') == null) return
    const zip = document.getElementById('js-zipcode')
    if (zip) {
      zip.addEventListener('blur', function () {
        AjaxZip3.zip2addr(this, '', 'your_address', 'your_address')
      })
    }
  }
}
