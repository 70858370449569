//UA判定
export function UaCheck() {
  let ua = window.navigator.userAgent.toLowerCase()
  return {
    ltIE6: typeof window.addEventListener == 'undefined' && typeof document.documentElement.style.maxHeight == 'undefined',
    ltIE7: typeof window.addEventListener == 'undefined' && typeof document.querySelectorAll == 'undefined',
    ltIE8: typeof window.addEventListener == 'undefined' && typeof document.getElementsByClassName == 'undefined',
    Tablet:
      (ua.indexOf('windows') != -1 && ua.indexOf('touch') != -1 && ua.indexOf('tablet pc') == -1) ||
      ua.indexOf('ipad') != -1 ||
      (ua.indexOf('android') != -1 && ua.indexOf('mobile') == -1) ||
      (ua.indexOf('firefox') != -1 && ua.indexOf('tablet') != -1) ||
      ua.indexOf('kindle') != -1 ||
      ua.indexOf('silk') != -1 ||
      ua.indexOf('playbook') != -1,
    Mobile:
      (ua.indexOf('windows') != -1 && ua.indexOf('phone') != -1) ||
      ua.indexOf('iphone') != -1 ||
      ua.indexOf('ipod') != -1 ||
      (ua.indexOf('android') != -1 && ua.indexOf('mobile') != -1) ||
      (ua.indexOf('firefox') != -1 && ua.indexOf('mobile') != -1) ||
      ua.indexOf('blackberry') != -1,
  }
}

export function IsIE() {
  return navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1
}

export function IsChrome() {
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.indexOf('msie ') > -1 || ua.indexOf('trident/') > -1) {
    console.log('ブラウザはInternet Explorerです')
    return false
  } else if (ua.indexOf('edge') != -1 || ua.indexOf('edg') != -1) {
    console.log('ブラウザはEdgeです')
    return false
  } else if (ua.indexOf('chrome') != -1) {
    console.log('ブラウザはChromeです')
    return true
  } else if (ua.indexOf('safari') != -1) {
    console.log('ブラウザはSafariです')
    return false
  } else if (ua.indexOf('firefox') != -1) {
    console.log('ブラウザはFireFoxです')
    return false
  }
}

export function IsFirefox() {
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.indexOf('msie ') > -1 || ua.indexOf('trident/') > -1) {
    console.log('ブラウザはInternet Explorerです')
    return false
  } else if (ua.indexOf('edge') != -1 || ua.indexOf('edg') != -1) {
    console.log('ブラウザはEdgeです')
    return false
  } else if (ua.indexOf('chrome') != -1) {
    console.log('ブラウザはChromeです')
    return false
  } else if (ua.indexOf('safari') != -1) {
    console.log('ブラウザはSafariです')
    return false
  } else if (ua.indexOf('firefox') != -1) {
    console.log('ブラウザはFireFoxです')
    return true
  }
}

export function IsSmartPhone() {
  if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    return true
  } else {
    return false
  }
}

export function IsTouchDevice() {
  return 'orientation' in window && (navigator.msPointerEnabled || 'ontouchstart' in window) ? true : false
}
