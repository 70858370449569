export class ScrollAnimation {
  constructor() {
    // 使用方法
    // <div class="js-anim_elm" data-margin="-10%">
    // 現在デフォルトの発火位置は-35%になっていますが、data-marginを指定すると、その要素はその位置で発火します。
    // data-marginを指定しないと、デフォルトのmarginの位置で発火します。
    // 例）
    // <div class="js-anim_elm" data-margin="-10%"> -> -10%
    // <div class="js-anim_elm"> -> -35%
    // <div class="js-anim_elm" data-margin=""> -> -35%
    // <div class="js-anim_elm" data-margin="亜wgなw"> -> -35%
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementsByClassName('js-anim_elm').length == 0) return
      const boxes = document.getElementsByClassName('js-anim_elm')
      const node = Array.prototype.slice.call(boxes, 0)
      const defaultMargin = '-35%' // ここでデフォルトを書き換え
      const keys = []
      const elements = {}

      const doWhenIntersect = function (entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('is-act')
          }
        })
      }

      node.forEach(function (box) {
        let key = box.getAttribute('data-margin')
        if (key !== null && key !== '' && key.match(/-*[0-9]*%/)) {
          if (key.replace('%', '') - 0 < -50) {
            key = '-50%'
            box.setAttribute('data-margin', key)
          }
          if (keys.indexOf(key) == -1) {
            keys.push(key)
            elements[key] = []
          }
          elements[key].push(box)
        } else {
          if (keys.indexOf(defaultMargin) == -1) {
            keys.push(defaultMargin)
            elements[defaultMargin] = []
          }
          elements[defaultMargin].push(box)
        }
      })

      keys.forEach(function (key) {
        const observer = new IntersectionObserver(doWhenIntersect, { root: null, rootMargin: key + ' 0px', threshold: 0 })
        elements[key].forEach(function (element) {
          observer.observe(element)
        })
      })
    })
  }
}
