import Swiper, { Navigation, Scrollbar } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'

export class IndexCaseSlider {
  constructor() {
    if (document.getElementById('js-index-case_slider') == null) return
    const sliderEl = document.getElementById('js-index-case_slider')

    const mySwiper = new Swiper(sliderEl, {
      modules: [Scrollbar, Navigation],
      slidesPerView: 1.15,
      spaceBetween: 30,
      navigation: {
        nextEl: '.js-case_arrow_next',
        prevEl: '.js-case_arrow_prev',
      },
      scrollbar: {
        el: '#js-case_scrollbar',
        draggable: false,
      },
      breakpoints: {
        767: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 2.7,
          spaceBetween: 30,
        },
        1400: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
    })
  }
}
