import Swiper, { Pagination } from 'swiper'
import 'swiper/css'

export class RecruitLeadingSlider {
  constructor() {
    if (document.getElementById('js-recruit_leading_slider') == null) return
    const sliderEl = document.getElementById('js-recruit_leading_slider')

    const mySwiper = new Swiper(sliderEl, {
      modules: [Pagination],
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: '.c-slider_pagenation',
      },
    })
  }
}
