import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

export class LowerCaseSlider {
  constructor() {
    if (document.getElementById('js-lower-case_slider') == null) return
    const sliderEl = document.getElementById('js-lower-case_slider')
    sliderEl.style.overflow = 'visible'

    const mySwiper = new Swiper(sliderEl, {
      modules: [Navigation],
      slidesPerView: 1.1,
      spaceBetween: 20,
      loop: true,
      navigation: {
        nextEl: '.js-case_arrow_next',
        prevEl: '.js-case_arrow_prev',
      },
      breakpoints: {
        767: {
          slidesPerView: 1.1,
          spaceBetween: 20,
        },
        1000: {
          slidesPerView: 1.35,
          spaceBetween: 50,
        },
      },
    })
  }
}
