'use strict'

require('picturefill')
require('intersection-observer')

// import { Budoux } from './lib/Budoux'
// import { ShowPagetop } from './lib/ShowPagetop'
import { Smooth } from './lib/Smooth'
import { TelGrant } from './lib/TelGrant'
import { EqualHeight } from './lib/EqualHeight'
import { Drawer } from './lib/Drawer'
import { Svg } from './lib/Svg'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { IeModal } from './lib/IeModal'
import { WindowStatus } from './lib/WindowStatus'
import { ViewportHeight } from './lib/ViewportHeight'
import { Zipcode } from './lib/Zipcode'
import { IndexCaseSlider } from './lib/IndexCaseSlider'
import { IndexKvSlider } from './lib/IndexKvSlider'
import { MegaMenu } from './lib/MegaMenu'
import { IndexColumnSlider } from './lib/IndexColumnSlider'
import { Accordion } from './lib/Accordion'
import { Opening } from './lib/Opening'
import { TextSplit } from './lib/TextSplit'
import { LowerCaseSlider } from './lib/LowerCaseSlider'
import { FeatureSection } from './lib/FeatureSection'
import { CaseSingleSlider } from './lib/CaseSingleSlider'
import { BgScroll } from './lib/BgScroll'
import { LowerColumnSlider } from './lib/LowerColumnSlider'
import { RecruitLeadingSlider } from './lib/RecruitLeadingSlider'
import { InputFile } from './lib/InputFile'
import { RecruitNumberCount } from './lib/RecruitNumberCount'
import { RecruitOpening } from './lib/RecruitOpening'
import { AccordionSlide } from './lib/AccordionSlide'
import { AnchorSidebar } from './lib/AnchorSidebar'
import { RecruitGallery } from './lib/RecruitGallery'
import { IndexKvFixed } from './lib/IndexKvFixed'
import { VueSearchBaseInformation } from './lib/VueSearchBaseInformation'
import { InterviewPic } from './lib/InterviewPic'

// new Budoux()
// new ShowPagetop()
new IeModal('サイト名', 'サイトURL')
new WindowStatus()
new ViewportHeight()
new Svg()
new ScrollAnimation()
new Drawer()
new Smooth()
new EqualHeight('.js-max_height')
new TelGrant()
new Zipcode()
new IndexCaseSlider()
new IndexKvSlider()
new MegaMenu()
new IndexColumnSlider()
new Accordion()
new Opening()
new TextSplit()
new LowerCaseSlider()
new FeatureSection()
new CaseSingleSlider()
new BgScroll()
new LowerColumnSlider()
new RecruitLeadingSlider()
new InputFile()
new RecruitNumberCount()
new RecruitOpening()
new AccordionSlide()
new AnchorSidebar()
new RecruitGallery()
new IndexKvFixed()
new VueSearchBaseInformation()
new InterviewPic()
