import Swiper from 'swiper'
import 'swiper/css'

export class CaseSingleSlider {
  constructor() {
    if (document.getElementById('js-case-single_slider') == null) return
    const sliderEl = document.getElementById('js-case-single_slider')
    sliderEl.style.overflow = 'visible'

    const mySwiper = new Swiper(sliderEl, {
      slidesPerView: 1.3,
      spaceBetween: 20,
      loop: true,
      breakpoints: {
        767: {
          slidesPerView: 1.7,
          spaceBetween: 20,
        },
        1024: {
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
      },
    })
  }
}
