// 使い方
// https://unionnet.esa.io/posts/1630

import { IsIE } from './Ua'

export class ViewportHeight {
  constructor() {
    if (IsIE()) return
    let currentHeight = window.innerHeight + 1
    const resize = () => {
      const innerHeight = window.innerHeight
      if (currentHeight == innerHeight) return
      currentHeight = innerHeight
      document.documentElement.style.setProperty('--vh', currentHeight / 100 + 'px')
    }
    resize()
    window.addEventListener('load', resize)
    window.addEventListener('resize', resize)
    window.addEventListener('orientationchange', () => {
      setTimeout(resize, 50)
    })
  }
}
