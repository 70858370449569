import Swiper from 'swiper'
import 'swiper/css'

export class IndexColumnSlider {
  constructor() {
    if (document.getElementById('js-index-column_slider') == null) return
    const sliderEl = document.getElementById('js-index-column_slider')

    const mySwiper = new Swiper(sliderEl, {
      loop: true,
      slidesPerView: 1.1,
      spaceBetween: 30,
    })
  }
}
