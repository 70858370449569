import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export class RecruitGallery {
  constructor() {
    const area = document.querySelector('.p-recruit_gallery')
    if (!area) return
    const initScrollTrigger = () => {
      // ScrollTrigger.config({
      //   autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load',
      // })
      const resize = () => {
        ScrollTrigger.refresh()
      }
      removeEventListener('resize', resize)
      addEventListener('resize', resize)
      ScrollTrigger.matchMedia({
        '(min-width: 1024px)': function () {
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: area,
              anticipatePin: 1,
              start: 'top top',
              // endTrigger: '.p-index_service',
              end: 'bottom bottom',
              pin: true,
              scrub: 7,
              pinSpacing: false,
              invalidateOnRefresh: true,
            },
          })
          gsap.set('.p-recruit_gallery__pic.-pic01', {
            top: '0',
            opacity: 0,
          })
          gsap.set('.p-recruit_gallery__pic.-pic02', {
            bottom: '8%',
            left: '-10%',
            opacity: 0,
          })
          gsap.set('.p-recruit_gallery__pic.-pic03', {
            opacity: 0,
            bottom: '−20%',
            left: '39%',
          })
          gsap.set('.p-recruit_gallery__pic.-pic04', {
            opacity: 0,
            top: '0%',
            right: '10%',
          })
          gsap.set('.p-recruit_gallery__pic.-pic05', {
            opacity: 0,
            bottom: '5%',
            right: '0',
          })
          tl.to('.p-recruit_gallery__pic.-pic01', {
            duration: 1,
            opacity: '1',
            top: '17%',
          })
            .to('.p-recruit_gallery__pic.-pic02', {
              duration: 1,
              opacity: 1,
              left: '-5%',
              bottom: '8%',
            })
            .to('.p-recruit_gallery__pic.-pic03', {
              duration: 1,
              opacity: 1,
              bottom: '1%',
              left: '39%',
            })
            .to('.p-recruit_gallery__pic.-pic04', {
              duration: 1,
              opacity: 1,
              top: '15%',
              right: '10%',
            })
            .to('.p-recruit_gallery__pic.-pic05', {
              duration: 1,
              opacity: 1,
              bottom: '5%',
              right: '-5%',
            })
            .to('.p-recruit_gallery__pic.-pic01', {
              duration: 1,
              right: '0',
            })
            .to('.p-recruit_gallery__pic.-pic02', {
              transform: 'translateX(0)',
              duration: 1,
              opacity: '1',
            })
            .to('.p-recruit_gallery__pic.-pic03', {
              duration: 1,
              opacity: 1,
            })
            .to('.p-recruit_gallery__pic.-pic04', {
              duration: 1,
              opacity: 1,
            })
            .to('.p-recruit_gallery__pic.-pic05', {
              duration: 1,
              opacity: 1,
            })
        },
        '(max-width: 1023px)': function () {
          return
        },
      })
    }
    initScrollTrigger()
    // ScrollTrigger.refresh()

    // const resizeWindow = () => {
    //   window.addEventListener(
    //     'resize',
    //     () => {
    //       window.location.reload()
    //     },
    //     false
    //   )
    // }
  }
}
