export class EqualHeight {
  constructor(elm, column) {
    this.elm = elm
    this.column = column

    const targetList = document.querySelectorAll(this.elm)
    const node = Array.prototype.slice.call(targetList, 0)
    const num = this.column

    if (targetList.length > 0) {
      const setting = () => {
        node.forEach(function (element, i) {
          if (i === 0 || i % num === 0) {
            let height = element.clientHeight
            for (let n = i + 1; n <= i + num - 1; n++) {
              if (height < node[n].clientHeight) {
                height = node[n].clientHeight
              }
            }
            for (let n = i; n <= i + num - 1; n++) {
              node[n].style.height = height + 'px'
            }
          }
        })
      }
      window.addEventListener('load', function () {
        setTimeout(setting, 1000)
      })
    }
  }
}
