import { gsap } from 'gsap'

export class Opening {
  constructor() {
    const tl = gsap.timeline()
    const target = document.getElementById('js-index_kv')
    const slider_f = document.querySelector('.slider_first')
    const slider_s = document.querySelector('.slider_second')
    const copy = document.querySelector('.p-index_kv__inner__copy')
    const x = document.querySelector('.p-index_kv__x')
    const hero = document.querySelector('.p-index_kv__hero')

    if (target) {
      tl.from(
        slider_f,
        {
          onComplete: function () {
            slider_f.classList.add('is-act')
          },
        },
        '+=0.1'
      )
        .from(
          slider_s,
          {
            onComplete: function () {
              slider_s.classList.add('is-act')
            },
          },
          '-=0.5'
        )
        .from(
          copy,
          {
            onComplete: function () {
              copy.classList.add('is-act')
            },
          },
          '+=0.3'
        )
        .from(
          x,
          {
            onComplete: function () {
              x.classList.add('is-act')
            },
          },
          '-=0.5'
        )
        .from(
          hero,
          {
            onComplete: function () {
              hero.classList.add('is-act')
            },
          },
          '-=0.75'
        )
    }
  }
}
